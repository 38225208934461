import React from "react"
import { navigate } from "gatsby"
import Recaptcha from "react-google-recaptcha"
import styled from "styled-components"
import Layout from "../../components/common/layout/layout"

import { Container, Section } from "../global"

console.log(process.env)

const RECAPTCHA_KEY = "6LckxckZAAAAAKilL9N44sI2X-40Kd9isRryy3LL" //process.env.GATSBY_APP_SITE_RECAPTCHA_KEY
if (typeof RECAPTCHA_KEY === "undefined") {
  throw new Error(`
  Env var GATSBY_APP_SITE_RECAPTCHA_KEY is undefined! 
  You probably forget to set it in your Netlify build environment variables. 
  Make sure to get a Recaptcha key at https://www.netlify.com/docs/form-handling/#custom-recaptcha-2-with-your-own-settings
  Note this demo is specifically for Recaptcha v2
  `)
}

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const StyledSection = styled(Section)`
  background-color: ${(props) => props.theme.color.background.light};
  clip-path: polygon(0 0, 100% 14%, 100% 100%, 0% 100%);
`

const GetStartedContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px 0 40px;
`

const GetStartedTitle = styled.h3`
  margin: 0 auto 32px;
  text-align: center;
`

const FormInput = styled.input`
  padding: 4px 6px;
  border-radius: 4px;
  min-width: 300px;
`

const TextArea = styled.textarea`
  padding: 4px 6px;
  border-radius: 4px;
  min-width: 300px;
`

const TryItButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${(props) => props.theme.color.secondary};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-left: 0;
  }
`

const Subtitle = styled.span`
  ${(props) => props.theme.font_size.xxsmall}
  padding-top: 16px;
  font-size: 14px;
  color: ${(props) => props.theme.color.primary};
`

export default function Contact() {
  const [state, setState] = React.useState({})
  const recaptchaRef = React.createRef()

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    const recaptchaValue = recaptchaRef.current.getValue()
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        "g-recaptcha-response": recaptchaValue,
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error))
  }

  return (
    <StyledSection>
      <GetStartedContainer>
        <GetStartedTitle>Contact Us.</GetStartedTitle>
        <h5>Drop us a line and we'll get back to you within 24 hours.</h5>
        <form
          name="contact-recaptcha"
          method="post"
          action="/thanks/"
          data-netlify="true"
          data-netlify-recaptcha="true"
          onSubmit={handleSubmit}
        >
          <noscript>
            <Subtitle>This form won't work with Javascript disabled</Subtitle>
          </noscript>
          <p>
            <label>
              Name:
              <br />
              <FormInput type="text" name="name" onChange={handleChange} />
            </label>
          </p>
          <p>
            <label>
              Email:
              <br />
              <FormInput type="email" name="email" onChange={handleChange} />
            </label>
          </p>
          <p>
            <label>
              Phone:
              <br />
              <FormInput type="phone" name="phone" onChange={handleChange} />
            </label>
          </p>
          <p>
            <label>
              Message:
              <br />
              <TextArea name="message" onChange={handleChange} />
            </label>
          </p>
          <Recaptcha ref={recaptchaRef} sitekey={RECAPTCHA_KEY} />
          <p>
            <TryItButton type="submit">Send</TryItButton>
          </p>
        </form>
      </GetStartedContainer>
    </StyledSection>
  )
}

import React from "react"
import styled from "styled-components"

import logo from "../../images/product/logo.svg"

import { Container } from "../global"

const Footer = () => (
  <FooterWrapper id="footer">
    {/* <FooterColumnContainer>
      <FooterColumn>
        <span>Writing</span>
        <ul>
          <li>Obituaries</li>
          <li>Personal & Family Histories</li>
        </ul>
      </FooterColumn>
      <FooterColumn>
        <span>Business Services</span>
        <ul>
          <li>Technical Writing</li>
          <li>Business Communications Consulting</li>
        </ul>
      </FooterColumn>
      <FooterColumn>
        <span>Editing</span>
        <ul>
          <li>Manuscript Editing</li>
          <li>Résumé & Cover Letter Editing</li>
        </ul>
      </FooterColumn>
      <FooterColumn>
        <span>Scholastics</span>
        <ul>
          <li>Scholarship Application (Editing Only)</li>
          <li>College & Graduate School Application (Editing Only)</li>
        </ul>
      </FooterColumn>
    </FooterColumnContainer> */}
    <BrandContainer>
      <Logo>
        <ImageWrapper>
          <img src={logo} alt="Logo" style={{ width: "40px" }} />
        </ImageWrapper>
        TheRhetoricians.com
      </Logo>
    </BrandContainer>
  </FooterWrapper>
)

const FooterWrapper = styled.footer`
  background-color: white;
  margin: 80px 0 0;
  padding: 0 0 80px;
`

const Logo = styled.div`
  font-family: ${(props) => props.theme.font.crimsontext};
  ${(props) => props.theme.font_size.regular};
  color: ${(props) => props.theme.color.black.regular};
  text-decoration: none;
  letter-spacing: 1px;
  margin: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 9;
  text-decoration: none;
  outline: 0px;
`

const BrandContainer = styled(Container)`
  position: relative;
  padding-top: 48px;
  display: flex;
  align-items: flex-end;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
  }
`
const FooterColumnContainer = styled(Container)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 32px;
  justify-content: start;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
  }
`
const ImageWrapper = styled.div`
  display: inline;
`

const FooterColumn = styled.div`
  span {
    font-size: 16px;
    font-family: ${(props) => props.theme.font.bold};
    color: ${(props) => props.theme.color.primary};
  }
  ul {
    list-style: none;
    margin: 16px 0;
    padding: 0;
    color: ${(props) => props.theme.color.black.regular};
    li {
      margin-bottom: 12px;
      font-family: ${(props) => props.theme.font.normal};
      font-size: 15px;
    }
  }
`

export default Footer

import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"
import logo from "../../images/product/logo.svg"

const Features = () => (
  <Section id="services">
    <StyledContainer>
      <ImageWrapper>
        <img
          src={logo}
          alt="Dr. Fred Pinnegar, PhD"
          style={{ width: "200px" }}
        />
      </ImageWrapper>
      <SectionTitle>Dr Fred Pinnegar, PhD.</SectionTitle>
      <Subtitle>Professional Writing Services</Subtitle>
      <FeaturesGrid>
        <FeatureItem>
          <FeatureTitle>Manuscripts</FeatureTitle>
          <FeatureText>
            Direct experience in the publishing business. From content to cover
            design (and everything in between) we can help.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>College & Graduate School Applications</FeatureTitle>
          <FeatureText>
            Served on multiple university admission committees, reviewing and
            deciding upon thousands of student applications. We know what stands
            out.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Personal & Family Histories</FeatureTitle>
          <FeatureText>
            We collect oral and written histories to document life's cherished
            experiences. Printing & binding.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Obituaries</FeatureTitle>
          <FeatureText>
            We capture personality and character writing memorials to those who
            have passed.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Technical Writing</FeatureTitle>
          <FeatureText>
            Progress reports. Final reports. Manuals. Federal & State compliance
            documentation.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>
            Custom Engraving; Recognition and Award Plaques
          </FeatureTitle>
          <FeatureText>
            Content writing for plaques and award certificates. Engravings and
            plaques available through First Class Engraving.
          </FeatureText>
        </FeatureItem>
      </FeaturesGrid>
    </StyledContainer>
  </Section>
)

export default Features

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${(props) => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const ContactLine = styled.h5`
  font-size: 16px;
  letter-spacing: 0px;
  margin: 5px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${(props) => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`

const FeatureText = styled.p`
  text-align: center;
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 250px;
`
